<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">
        Plots List
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Plots</a></li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-3">
        <form action="#">
          <div class="form-group d-lg-flex">
            <input type="number" class="form-control" @change="paginateChange" placeholder="perPage" v-model="paginate">
          </div>
        </form>
      </div>
      <div class="col-9">
        <form action="#">
          <div class="form-group d-lg-flex">
            <input type="text" class="form-control" placeholder="Search Here" v-model="searchInput">
            <button type="button" class="btn btn-primary ml-0 ml-lg-3 mt-2 mt-lg-0" @click="searchPlots" :disabled="searchInput == ''">Search</button>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <h4 class="card-title">Plots</h4>
              <download-excel
                class="btn btn-success"
                :data="plots"
                :fields="json_fields"
                worksheet="My Worksheet"
                name="plots.xlsx">
                Download Plots
              </download-excel>
            </div>
            <b-table :items="plots" :busy="isBusy" id="table-list" responsive :per-page="perPage" :current-page="currentPage" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template v-slot:cell(coords)="data">
                <span>{{ (data.item.coords == null) ? "N/A" : coords }}</span>
              </template>
              <template v-slot:cell(neighborhood)="data">
                <span>{{ (data.item.neighborhood == null) ? "N/A" : neighborhood }}</span>
              </template>
              <template v-slot:cell(classification)="data">
                <span>{{ (data.item.classification == null) ? "N/A" : data.item.classification }}</span>
              </template>
              <template v-slot:cell(created_at)="data">
                <span>{{ formatTime(data.item.created_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template> <!--
              <template v-slot:cell(action)="data">
                <b-badge pill variant="outline-info">
                  <router-link :to="{ name: 'plot-view', params: {id: data.item.id} }">View</router-link>
                </b-badge>
                <b-badge pill variant="outline-info">
                  <router-link :to="{ name: 'plot-edit', params: {id: data.item.id} }">Edit</router-link>
                </b-badge>
              </template> -->
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right">
            </b-pagination>
          </div>
        </div>
      </div>
      </div>
    <div>
    </div>
  </section>
</template>
<script>
import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"

import helper from '../../util/helper.js'
import _ from "lodash";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  data: function() {
    return {
      searchInput: "",
      sortBy: 'name',
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      currentPage: 1,
      perPage: 0,
      paginate: 10,
      isBusy: true,
      fields: [
        { key: 'id', sortable: true },
        { key: 'plan_id', sortable: true },
        { key: 'plot', sortable: true },
        { key: 'meter_sq', sortable: true},
        { key: 'classification', sortable: true},
        { key: 'neighborhood', sortable: true},
        { key: 'coords', sortable: false},
        { key: 'no_of_favourite', sortable: true},
        { key: 'no_of_feedback', sortable: true},
        { key: 'created_at', sortable: false},
        // { key: 'action', sortable: false}
      ],
      json_fields: {
        'ID': 'id',
        "Plot Number": "plot",
        'Classification': 'classification',
        'plan_id': 'plan_id',
        'Area Size': 'meter_sq',
        'Created At': 'created_at',
      }
    };
  },
  computed: {
    rows() {
      return this.$store.state.plot.meta.total
    },
    paginationPageSize() {
      return this.$store.state.transaction.meta.paginate
    },
    plots() {
      return this.$store.state.plot.plots
    }
  },
  watch: {
    currentPage() {
      if(this.currentPage != this.$store.state.transaction.meta.current_page)
        this.fetchPlots(this.currentPage)
    },
    searchInput() {
      if (this.searchInput == "")
        this.fetchPlots()
    }
  },
  methods: {
    formatTime(time, format) {
      return helper.formatTime(time, format)
    },
    searchPlots() {
      if(this.searchInput == "")
        return
      this.isBusy = true
      let payload = {
        meta: {
          term: this.searchInput,
        }
      }

      this.$store.dispatch("plot/searchPlots", payload).then(() => {
        this.isBusy = false
      })
    },
    fetchPlots(current_page = 1) {
      this.isBusy = true

      const payload = {
        meta: {
          page: current_page,
          paginate: this.paginate
        },
      }
      this.$store.dispatch("plot/fetchPlots", payload)
      .then(() => {
        this.isBusy = false
      })
    },
    paginateChange: _.debounce(function() {
      if (this.paginate > 0 && this.paginate != "")
        this.fetchPlots()
    }, 500),
  },
  created() {
    this.fetchPlots()
 },
}
</script>
